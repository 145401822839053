<!--
  PACKAGE_NAME : src\pages\esp\system\menu\main-page\list.vue
  FILE_NAME : list
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 메인 페이지 설정
-->
<template>
  <div class="container">
    <div class="page-sub-box">
      <div class="flex gap-x-6 h-auto">
        <div class="w-6/12">
          <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" @row-click="onRowClick" />
        </div>
        <div class="w-6/12 pad_top55">
          <EditForm
            :key="selectedDataRowId"
            ref="editForm"
            :disableInfo="disableInfo"
            :selectedData="selectedData"
            :dashBoardList="dashBoardList"
            @saveEditForm="onSaveEditForm"
            @closeEditForm="onCloseEditForm"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import EditForm from './edit-form.vue';
  import { isSuccess } from '@/plugins/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
      EditForm,
    },
    data() {
      return {
        dashBoardList: [],
        itemList: [],
        dataGrid: {
          keyExpr: 'id',
          refName: 'mainSettingList',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: true, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          focusedRowEnabled: false, //포커스된 행 표시 유무
          hoverStateEnabled: true, // 마우스 오버시 행 표시 유무
          rowAlternationEnabled: false,
          dataSource: [],
          apiActionNm: {},
          showActionButtons: {
            // 그리드 버튼 노출 설정값
            update: false,
            delete: false,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: this.$_msgContents('COMPONENTS.ADD', { defaultValue: '추가' }),
                  elementAttr: { class: 'btn_XS default filled add1' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.refreshGridData();
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: this.$_msgContents('COMPONENTS.DELETE', { defaultValue: '삭제' }),
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.onDeleteData();
                  },
                },
                location: 'before',
              },
            ],
          },
          customEvent: {
            rowClick: true,
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: false,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            filtering: false,
            sorting: false,
            grouping: false,
            paging: true,
          },
          paging: {
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          scrolling: {
            mode: 'standard', //스크롤 모드 : ['infinite', 'standard', 'virtual']
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: false, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: false, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false,
            allowDeleting: false,
            allowAdding: false,
            mode: 'row', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'onClick', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: 'ID',
              dataField: 'id',
              alignment: 'center',
              width: 80,
              visible: true,
              sortOrder: 'none',
            },
            {
              caption: '설정 대상',
              i18n: 'COMMON.WORD.SETTING_TARGET',
              dataField: 'targetField',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              lookup: {
                dataSource: this.$_enums.common.mainTargetField.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '메인 페이지 타입',
              i18n: 'COMMON.WORD.MAIN_PAGE_TYPE',
              dataField: 'pageType',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              lookup: {
                dataSource: this.$_enums.common.mainPageType.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '수정일시',
              i18n: 'COMPONENTS.MODIFY_DATE_TIME',
              dataType: 'date',
              dataField: 'editDt',
              format: 'yyyy-MM-dd HH:mm:ss',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
            },
          ],
        },
        selectedDataRowId: 0,
        selectedData: {},
      };
    },
    computed: {
      disableInfo() {
        const items = this.itemList || [];
        return items.reduce(
          (acc, item) => {
            acc[item.targetField] = acc[item.targetField] || [];
            const validValues = item.targetIds.split(',').filter(value => value.trim() !== '');
            acc[item.targetField].push(...validValues);
            return acc;
          },
          {
            [this.$_enums.common.mainTargetField.AUTH_ID.value]: [],
            [this.$_enums.common.mainTargetField.LOGIN_ID.value]: [],
          },
        );
      },
    },
    methods: {
      /**
       * @description 그리드 클릭 이벤트
       * @param e
       */
      onRowClick(e) {
        this.selectedDataRowId = e.data.id;
        this.selectedData = e.data;
      },
      /**
       * @description 그리드 데이터 갱신
       */
      refreshGridData() {
        this.$refs.mainSettingList.getGridInstance.refresh();
        this.selectedDataRowId = 0;
        this.selectedData = {};
        this.itemList = [...this.$refs.mainSettingList?.getItems];
      },
      /**
       * @description 데이터 삭제 이벤트
       * @return {Promise<void>}
       */
      async onDeleteData() {
        const selectedRows = this.$refs.mainSettingList.selectedRowsData;
        if (selectedRows.length === 0) {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        if (await this.$_Confirm(this.$_msgContents('CMN_CFM_DELETE_SELECTED', { defaultValue: '선택한 데이터를 삭제하시겠습니까?' }))) {
          const payload = {
            actionname: 'MENU_MAIN_PAGE_DELETE',
            data: {
              data: selectedRows.map(row => row.id),
            },
            useErrorPopup: true,
          };

          const res = await this.CALL_API(payload);
          if (isSuccess(res)) {
            this.refreshGridData();
          }
        }
      },
      /**
       * 메인 페이지 설정 정보 조회
       *
       * @return {Promise<{data: *, totalCount}>}
       */
      async onSearch() {
        const vm = this;
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          async load(loadOptions) {
            const params = vm.$_getDxDataGridParam(loadOptions);

            const payload = {
              actionname: 'MENU_MAIN_PAGE_LIST',
              data: { ...params },
              loading: false,
              useErrorPopup: true,
            };

            let rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await vm.CALL_API(payload);
            if (isSuccess(res)) {
              rtnData = {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };
            }
            return rtnData;
          },
        });
      },
      /**
       * @description 수정폼 저장 이벤트
       */
      onSaveEditForm() {
        this.refreshGridData();
      },
      /**
       * @description 수정폼 닫기 이벤트
       */
      onCloseEditForm() {
        this.refreshGridData();
      },
    },
    created() {},
    mounted() {
      this.onSearch();
    },
  };
</script>
<style scoped></style>
